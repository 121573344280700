/**
 * External Dependencies
 */

import 'jquery';
var Flickity = require('flickity');
import Splitting from 'splitting';
import ScrollOut from 'scroll-out';
import * as basicScroll from 'basicscroll';
import { Fancybox } from "@fancyapps/ui";
var $ = require('jquery');

$(document).ready(function() {

  window.Fancybox = Fancybox

  Fancybox.bind("[data-fancybox]", {

  });

  // SERVICES
  $('.services__link').hover(
    function () {
      var serviceId = $(this).data('id');
      $('.services__image').removeClass('active');
      $(`.services__image[data-id=${serviceId}]`).addClass('active');
    },
    function () {

    }
  );

  // handle links with @href started with '#' only
  $(document).on('click', 'a[href^="#"]', function(e) {
      // target element id
      var id = $(this).attr('href');

      // target element
      var $id = $(id);
      if ($id.length === 0) {
        return;
      }

      // prevent standard hash navigation (avoid blinking in IE)
      e.preventDefault();

      // top position relative to the document
      var pos = $id.offset().top - 80;

      // animated top scrolling
      $('body, html').animate({scrollTop: pos});
  });

  // SCROLL TEXT
  $('#text-path').each(function() {
    var textPath = document.querySelector('#text-path');

    var textContainer = document.querySelector('#text-container');

    var path = document.querySelector( textPath.getAttribute('href') );

    var pathLength = path.getTotalLength();
    // console.log(pathLength);

    function updateTextPathOffset(offset){
      textPath.setAttribute('startOffset', offset);
    }

    updateTextPathOffset(pathLength);

    function onScroll(){
      requestAnimationFrame(function(){
        var rect = textContainer.getBoundingClientRect();
        var scrollPercent = rect.y / window.innerHeight;
        // console.log(scrollPercent);
        updateTextPathOffset( scrollPercent * 2 * pathLength - 2000 );
      });
    }

    window.addEventListener('scroll',onScroll);
  });

  // SCROLLED NAV
  $(window).on('load scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });

  // RANDOM WORD
  var words = $('.randomword').length;
  function fadeWords(nr) {
    if (nr == words) {
      fadeWords(0);
    } else {
      $('.randomword').eq(nr).addClass('active');
      setTimeout(function() {
        $('.randomword').eq(nr).removeClass('active');
        fadeWords(nr+1);
      }, 2000);
    }
  }

  // RANDOM WORD
  if($('.randomword').length) {
    fadeWords(0);
  }

  // ACCORDION
  const items = document.querySelectorAll('.accordion__title');

  function toggleAccordion() {
    const itemToggle = this.getAttribute('aria-expanded');

    var i;

    for (i = 0; i < items.length; i++) {
      items[i].setAttribute('aria-expanded', 'false');
    }

    if (itemToggle == 'false') {
      this.setAttribute('aria-expanded', 'true');
    }
  }

  items.forEach(item => item.addEventListener('click', toggleAccordion));

  // BASIC SCROLL
  document.querySelectorAll('.scroll-scale').forEach((elem) => {
    basicScroll.create({
      elem: elem,
      from: 'top-middle',
      to: 'bottom-middle',
      direct: true,
      props: {
        '--tw-scale-y': {
          from: '1',
          to: '1.2',
        },
        '--tw-scale-x': {
          from: '1',
          to: '1.2',
        },
      },
    }).start()
  });

  // FILTERS
  $('.filter__blogs').change(handleNewsFilter);

  function handleNewsFilter () {

    var uristring = '?published=';

    $('.filter__blogs:checked').each(function(index) {
      var isLastElement = index == $('.filter__blogs:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  // MENU
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.hamburger-nav-bg').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  // SLIDER
  var galleryElems = document.querySelectorAll('.slider');
  for ( var i=0, len = galleryElems.length; i < len; i++ ) {
    var galleryElem = galleryElems[i];
    new Flickity( galleryElem, {
      prevNextButtons: true,
      draggable: true,
      pageDots: false,
      wrapAround: true,
    });
  }

  $('.image-grid__slider').each(function() {
    var carousel = document.querySelector('.image-grid__slider');
    var flkty = new Flickity( carousel, {
      prevNextButtons: false,
      draggable: false,
      pageDots: false,
      wrapAround: true,
    });
    // elements
    var cellsButtonGroup = document.querySelector('.image-grid__slider-dots');
    // var cellsButtons = utils.makeArray( cellsButtonGroup.children );
    var cellsButtons = Array.prototype.slice.call( cellsButtonGroup.children );

    // update buttons on select
    flkty.on( 'select', function() {
      var previousSelectedButton = cellsButtonGroup.querySelector('.is-selected');
      var selectedButton = cellsButtonGroup.children[ flkty.selectedIndex ];
      previousSelectedButton.classList.remove('is-selected');
      selectedButton.classList.add('is-selected');
    });

    // cell select
    cellsButtonGroup.addEventListener( 'click', function( event ) {
      var index = cellsButtons.indexOf( event.target );
      flkty.select( index );
    });
    // previous
    var previousButton = document.querySelector('.image-grid__slider-prev');
    previousButton.addEventListener( 'click', function() {
      flkty.previous();
    });
    // next
    var nextButton = document.querySelector('.image-grid__slider-next');
    nextButton.addEventListener( 'click', function() {
      flkty.next();
    });
  });

  // $('#input_1_18').parent().append(`
  //   <div class="zz-loader absolute inset-0 z-10 flex justify-center items-center bg-black/30 invisible opacity-0 transition duration-300 ease-in-out">
  //     <svg class="w-6 h-6 md:w-8 md:h-8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  //       viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
  //         <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
  //           <animateTransform
  //             attributeName="transform"
  //             attributeType="XML"
  //             type="rotate"
  //             dur="1s"
  //             from="0 50 50"
  //             to="360 50 50"
  //             repeatCount="indefinite" />
  //       </path>
  //     </svg>
  //   </div>`
  // );

  // $('#input_1_17').on('change', function(){
  //   var brand = $(this).find(':selected').val()

  //   if(brand && brand != '') {
  //     $('#input_1_18').html('').append($('<option>', {
  //       value: null,
  //       text: 'Selecteer een model'
  //     }));

  //     var ajax_url = ajax_object.ajax_url;

  //     $.ajax({
  //       url: ajax_url,
  //       type: "GET",
  //       data: {
  //         action: "zz_get_brand_models",
  //         brand: brand,
  //       },
  //       beforeSend: function() {
  //         $('.zz-loader').toggleClass('invisible opacity-0');
  //       },
  //       success: function (response){
  //         response = JSON.parse(response);
  //         if(response) {
  //           $('.zz-loader').toggleClass('invisible opacity-0');
  //           response.data.forEach((item) => {
  //             $('#input_1_18').append($('<option>', {
  //               value: item.id,
  //               text: item.name
  //             }));
  //           })
  //         }
  //       },
  //       error: function(XMLHttpRequest, textStatus, error) {
  //         if(XMLHttpRequest.status !== 200) {
  //           alert('Er was een probleem bij het ophalen van de gegevens. Probeer het later opnieuw of neem contact met ons op!');
  //         }
  //         console.log("Error Thrown: "+error);
  //         console.log("Text Status: "+textStatus);
  //         console.log(XMLHttpRequest);
  //       }
  //     });

  //   }
  // })

});
